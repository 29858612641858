/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Box } from "theme-ui";
import PropTypes from "prop-types";

function SectionWrapper({ children, sectionStyles, id, boxStyle }) {
  return (
    <section
      id={id}
      sx={{ variant: "layout.sectionPadding", ...sectionStyles }}
    >
      <Box
        sx={{
          variant: "layout.wrapper",
          ...boxStyle,
        }}
      >
        {children}
      </Box>
    </section>
  );
}

SectionWrapper.defaultProps = {
  id: "",
  sectionStyles: {},
  boxStyle: {},
};

SectionWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  sectionStyles: PropTypes.shape({}),
  boxStyle: PropTypes.shape({}),
  id: PropTypes.string,
};

export default SectionWrapper;
