/** @jsx jsx */
import { StaticImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import { jsx, Box, Grid, Badge, Flex } from "theme-ui";
import PropTypes from "prop-types";

function PlanetPeace({ user }) {
  const [index, setIndex] = useState(0);
  const list = [
    [
      user.name ? `${user.name}, ${user.country}` : "Annabelle, Finland",
      "Prakash, India",
      "John, US",
      "Bernardo, Portugal",
      "Vamsi, India",
      "Judith, Scotland",
      "Elizabeth, Australia",
    ],
    [
      "Kiran, India",
      "Vishal, Finland",
      "Ramesh, Portugal",
      "John, US",
      "Sam, Scotland",
      "Karthick, India",
      "Ravi, Australia",
    ],
    [
      "Barani, Australia",
      "kevin, India",
      "dhanush, India",
      "Ragnar, Finland",
      "Sabari, Portugal",
      "Rakesh, Scotland",
      "Saravanan, US",
    ],
  ];
  return (
    <React.Fragment>
      <Box mb="4" sx={{ color: "#76AA3A", lineHeight: 1.5 }}>
        <Flex
          sx={{
            fontSize: ["1.4em", "1.7em", "2em"],
            fontWeight: "bolder",
            alignItems: "center",
          }}
        >
          PLANeT PEACE{" "}
          <Badge
            sx={{
              letterSpacing: "1.5px",
              backgroundColor: "#0082BE",
              padding: "4px 8px",
              fontSize: "14px",
              fontWeight: "300",
              lineHeight: "1.5",
              ml: 2,
            }}
            variant="accent"
          >
            LIVE
          </Badge>
        </Flex>
        Plant seeds of peace for a peaceful planet, and share the message.
      </Box>
      <Grid
        mb="4"
        columns={[1, 1, 1, "2fr 1fr", "4fr 1fr"]}
        sx={{ justifyContent: "center", alignItems: "center" }}
      >
        <Box
          sx={{
            height: ["150px", "250px", null, null, "400px"],
            width: "100%",
          }}
        >
          <StaticImage
            src="../assets/img/location.png"
            width="100%"
            height="100%"
            sx={{ height: "100%", width: "100%" }}
            alt="Logo"
          />
        </Box>
        <Box>
          <Box sx={{ fontSize: "20px", mb: 4, color: "#1F1F1F" }}>
            WELCOME <br />
            <span sx={{ color: "#FFD600", fontWeight: "bold" }}>
              YOU JUST PLANTED A SEED OF PEACE
            </span>
          </Box>
          {list[index].map((item) => (
            <Box key={item} sx={{ fontSize: "13px", lineHeight: 2 }}>
              <span sx={{ fontWeight: "bold", textTransform: "capitalize" }}>
                {item.split(",")[0]}
              </span>
              ,<span>{item.split(",")[1]}</span>
            </Box>
          ))}
          <Flex sx={{ alignItems: "baseline", mt: "3" }}>
            <Box onClick={() => setIndex(0)}>
              <span
                sx={{
                  variant: "layout.dot",
                  width: "16px",
                  height: "16px",
                  cursor: "pointer",
                }}
              />
            </Box>
            <Box onClick={() => setIndex(1)}>
              <span
                sx={{
                  variant: "layout.dot",
                  width: "14px",
                  height: "14px",
                  opacity: "0.75",
                  cursor: "pointer",
                }}
              />
            </Box>
            <Box onClick={() => setIndex(2)}>
              <span
                sx={{
                  variant: "layout.dot",
                  width: "12px",
                  height: "12px",
                  cursor: "pointer",
                  opacity: "0.5",
                }}
              />
            </Box>
          </Flex>
        </Box>
      </Grid>
    </React.Fragment>
  );
}

PlanetPeace.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    country: PropTypes.string,
  }).isRequired,
};

export default PlanetPeace;
