/** @jsx jsx */
import React from "react";
import { faPowerOff } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { jsx, Box } from "theme-ui";
import { StaticImage } from "gatsby-plugin-image";
import Slider from "../component/Slider";

function PartnerOfPeace() {
  // const partner = [
  //   <StaticImage
  //     src="../assets/img/aum-hum.png"
  //     alt="Partner"
  //     sx={{ width: "135px", height: "63px" }}
  //     placeholder="blurred"
  //   />,
  //   <StaticImage
  //     src="../assets/img/juniper.png"
  //     alt="Partner"
  //     placeholder="blurred"
  //   />,
  //   <StaticImage
  //     src="../assets/img/the-global-foundation.png"
  //     alt="Partner"
  //     placeholder="blurred"
  //   />,
  // ];
  const styleProps = { color: "#4A4A49", fontSize: "26px !important", mb: 3 };
  const media = [
    <StaticImage
      src="../assets/img/aum-hum.png"
      alt="Partner"
      sx={{ width: "135px", height: "63px", marginRight: "30px" }}
      placeholder="blurred"
    />,
    <StaticImage
      sx={{ width: "135px", height: "63px", marginRight: "30px" }}
      src="../assets/img/juniper.png"
      alt="Partner"
      placeholder="blurred"
    />,
    <StaticImage
      sx={{ width: "135px", height: "63px", marginRight: "30px" }}
      src="../assets/img/the-global-foundation.png"
      alt="Partner"
      placeholder="blurred"
    />,
    <StaticImage
      sx={{ width: "135px", height: "63px", marginRight: "30px" }}
      src="../assets/img/brics.png"
      alt="Partner"
      placeholder="blurred"
    />,
    <StaticImage
      sx={{ width: "135px", height: "63px", marginRight: "30px" }}
      src="../assets/img/say.png"
      alt="Partner"
      placeholder="blurred"
    />,
    <StaticImage
      sx={{ width: "135px", height: "63px" }}
      src="../assets/img/fm.png"
      alt="Partner"
      placeholder="blurred"
    />,
    <StaticImage
      sx={{ width: "135px", height: "63px" }}
      src="../assets/img/eosg.png"
      alt="Partner"
      placeholder="blurred"
    />,
    <StaticImage
      sx={{ width: "135px", height: "63px" }}
      src="../assets/img/tgelf.png"
      alt="Partner"
      placeholder="blurred"
    />,
    <StaticImage
      sx={{ width: "135px", height: "63px" }}
      src="../assets/img/prana.png"
      alt="Partner"
      placeholder="blurred"
    />,
    <StaticImage
      sx={{ width: "135px", height: "63px" }}
      src="../assets/img/omni.png"
      alt="Partner"
      placeholder="blurred"
    />,
  ];

  const usaMedia = [
    <StaticImage
      src="../assets/img/american-association.png"
      alt="Partner"
      sx={{ width: "135px", height: "63px", marginRight: "30px" }}
      placeholder="blurred"
    />,
  ];

  const australiaMedia = [
    <StaticImage
      sx={{ width: "155px", height: "75px", marginBottom: "8px" }}
      src="../assets/img/australian-association.png"
      alt="Partner"
      placeholder="blurred"
    />,
    <StaticImage
      sx={{ width: "155px", height: "75px", marginBottom: "8px" }}
      src="../assets/img/pure-land.png"
      alt="Partner"
      placeholder="blurred"
    />,
    <StaticImage
      sx={{ width: "155px", height: "75px", marginBottom: "8px" }}
      src="../assets/img/dove-edited.png"
      alt="Partner"
      placeholder="blurred"
    />,
    <StaticImage
      sx={{ width: "155px", height: "75px", marginBottom: "8px" }}
      src="../assets/img/swan.png"
      alt="Partner"
      placeholder="blurred"
    />,
    <StaticImage
      sx={{ width: "155px", height: "75px", marginBottom: "8px" }}
      src="../assets/img/aus-logo.png"
      alt="Partner"
      placeholder="blurred"
    />,
  ];
  const medical = [
    <StaticImage
      sx={{ width: "155px", height: "75px", marginBottom: "8px" }}
      src="../assets/img/cipica.png"
      alt="Partner"
      placeholder="blurred"
    />,
    <StaticImage
      sx={{ width: "155px", height: "75px", marginBottom: "8px" }}
      src="../assets/img/cure.png"
      alt="Partner"
      placeholder="blurred"
    />,
    <StaticImage
      sx={{ width: "155px", height: "75px", marginBottom: "8px" }}
      src="../assets/img/heart.png"
      alt="Partner"
      placeholder="blurred"
    />,
    <StaticImage
      sx={{ width: "155px", height: "75px", marginBottom: "8px" }}
      src="../assets/img/heart-2.png"
      alt="Partner"
      placeholder="blurred"
    />,
    <StaticImage
      sx={{ width: "155px", height: "75px", marginBottom: "8px" }}
      src="../assets/img/jh-hospital.png"
      alt="Partner"
      placeholder="blurred"
    />,
    <StaticImage
      sx={{ width: "155px", height: "75px", marginBottom: "8px" }}
      src="../assets/img/medical.png"
      alt="Partner"
      placeholder="blurred"
    />,
    <StaticImage
      sx={{ width: "155px", height: "75px", marginBottom: "8px" }}
      src="../assets/img/mgm.png"
      alt="Partner"
      placeholder="blurred"
    />,
    <StaticImage
      sx={{ width: "155px", height: "75px", marginBottom: "8px" }}
      src="../assets/img/paras.png"
      alt="Partner"
      placeholder="blurred"
    />,
    <StaticImage
      sx={{ width: "155px", height: "75px", marginBottom: "8px" }}
      src="../assets/img/pats.png"
      alt="Partner"
      placeholder="blurred"
    />,
    <StaticImage
      sx={{ width: "155px", height: "75px", marginBottom: "8px" }}
      src="../assets/img/quo.png"
      alt="Partner"
      placeholder="blurred"
    />,
    <StaticImage
      sx={{ width: "155px", height: "75px", marginBottom: "8px" }}
      src="../assets/img/save-heart.png"
      alt="Partner"
      placeholder="blurred"
    />,
    <StaticImage
      sx={{ width: "155px", height: "75px", marginBottom: "8px" }}
      src="../assets/img/sneh.png"
      alt="Partner"
      placeholder="blurred"
    />,
    <StaticImage
      sx={{ width: "155px", height: "75px", marginBottom: "8px" }}
      src="../assets/img/spare.png"
      alt="Partner"
      placeholder="blurred"
    />,
  ];

  const contsinerStyle = {
    // backgroundColor: "#F4F4F4",
    minHeight: "30px",
    maxHeight: "100px",
  };
  return (
    <React.Fragment>
      <Box
        sx={{
          fontSize: ["22px", "25px", "30px"],
          alignItems: "center",
          color: "#76AA3A",
          marginBottom: ["18px", "22px", "26px"],
          fontWeight: "500",
        }}
      >
        Partners <FontAwesomeIcon icon={faPowerOff} size="xs" />f Peace
        <br />
      </Box>
      <br />
      <Slider
        containerStyle={contsinerStyle}
        title={<h1 sx={styleProps}>Medical field partners</h1>}
        imgSrc={medical}
      />
      <br />
      <Slider
        containerStyle={contsinerStyle}
        title={<h1 sx={styleProps}>India</h1>}
        imgSrc={media}
      />
      <br />
      <Slider
        containerStyle={contsinerStyle}
        title={<h1 sx={styleProps}>United States</h1>}
        imgSrc={usaMedia}
      />
      <br />
      <Box>
        <h1 sx={styleProps}>Europe</h1>
        <StaticImage
          sx={{ width: "135px", height: "63px" }}
          src="../assets/img/bethelove.png"
          alt="Partner"
          placeholder="blurred"
        />
        <StaticImage
          sx={{ width: "135px", height: "63px" }}
          src="../assets/img/dove.png"
          alt="Partner"
          placeholder="blurred"
        />
      </Box>
      <br />
      <Slider
        containerStyle={contsinerStyle}
        title={<h1 sx={styleProps}>Australia</h1>}
        imgSrc={australiaMedia}
      />
    </React.Fragment>
  );
}

export default PartnerOfPeace;
