// Custom fabric dialog styles for apps
export const appsDialogStyles = {
  small: {
    main: {
      selectors: {
        "@media (min-width: 480px)": {
          width: 300,
          minWidth: 300,
          maxWidth: 300,
          maxHeight: "100%",
        },
      },
    },
  },
  medium: {
    main: {
      selectors: {
        "@media (min-width: 480px)": {
          width: 600,
          minWidth: 600,
          maxWidth: 600,
          maxHeight: "100%",
        },
      },
    },
  },
  mediumX: {
    main: {
      selectors: {
        "@media (min-width: 480px)": {
          width: 850,
          minWidth: 850,
          maxWidth: 850,
          maxHeight: "100%",
        },
      },
    },
  },
  large: {
    main: {
      selectors: {
        "@media (min-width: 480px)": {
          width: "96%",
          maxWidth: 1200,
          maxHeight: "100%",
        },
      },
    },
  },
};

const dialogStyles = {
  appsDialogStyles,
};

export default dialogStyles;
