/** @jsx jsx */
import { jsx, Box, Flex, Grid, Select } from "theme-ui";
import { useEffect, useState } from "react";
import applyProdQaConfig from "../../../utils/applyProdQaConfig";
import SectionWrapper from "../Layout/SectionLayout";
import VideoModel from "../component/VideoModel";
import Timer from "../component/Timer";

const Events = () => {
  const [videoModel, setVideoModel] = useState(false);
  const [timerDisplay1, setTimerDisplay1] = useState(true);
  const [timerDisplay2, setTimerDisplay2] = useState(true);
  const [id, setId] = useState("");
  const [config, setConfig] = useState({});
  const celebrateTime = new Date("Sep 21, 2021 20:30:00").getTime();
  const createTime = new Date("Sep 21, 2021 21:00:00").getTime();

  useEffect(() => {
    const videoLang = applyProdQaConfig({
      prod: {
        videoJson:
          "https://static-file.web.app/conf/event-pages/peaceday2021/config.json",
      },
      qa: {
        videoJson:
          "https://static-file-staging.web.app/conf/event-pages/peaceday2021/config.json",
      },
    });
    fetch(videoLang.videoJson)
      .then((data) => data.json())
      .then((res) => setConfig(res));
  }, [setConfig]);

  const triggerModel = (value, path) => {
    const rrvalue = config[path]?.find((item) => item.language === value);
    setId(rrvalue?.link);
    setVideoModel(true);
  };
  const modalShow = () => {
    setVideoModel(false);
  };

  return (
    <SectionWrapper
      id="event"
      sectionStyles={{
        background:
          "transparent linear-gradient(90deg, #F5DA50 0%, #EEDF92 49%, #E9E1B9 100%) 0% 0% no-repeat padding-box",
      }}
    >
      {videoModel && <VideoModel modalShow={modalShow} id={id} />}
      <Box sx={{ color: "#000000" }}>
        <h1 sx={{ fontSize: ["20px", "30px", "35px"] }}>
          Become a Peace Messenger - Meditate
        </h1>
        <Grid columns={[1, 1, 2, 2]} mb="3">
          <Box sx={{ borderLeft: "1px solid #d0bd63", pl: "3" }}>
            <Flex
              sx={{ justifyContent: ["space-between", null, null, "start"] }}
            >
              <span sx={{ fontSize: "14px" }}>8.30 pm local time</span>
            </Flex>
            <h1
              sx={{
                fontSize: ["22px", "30px", "36px"],
                fontWeight: "400",
                mt: "2",
                mb: "2",
              }}
            >
              Celebrate Peace
            </h1>
            Inspirations of peace from around the world.
            {!timerDisplay1 && (
              <Flex
                sx={{
                  mt: [2, 2, 5],
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <Box
                  mr="3"
                  sx={{ flex: "1 1 1", fontWeight: "500", fontSize: "14px" }}
                >
                  WATCH HERE
                </Box>
                <Box mr="3" sx={{ flex: "1 1 1", alignItems: "center" }}>
                  <Select
                    defaultValue="Select Your Language"
                    sx={{
                      alignItems: "center",
                      cursor: "pointer",
                      borderRadius: 0,
                      // width: ["90px", "110px", "120px"],
                      width: "230px",
                      border: "solid 1px #a39864",
                      paddingLeft: ["8px", "16px", "16px"],
                      paddingRight: ["12px", "16px", "16px"],
                      fontSize: ["12px", "16px", "inherit"],
                      bg: "transparent",
                      outline: "none",
                    }}
                    onChange={(e) =>
                      triggerModel(e.target.value, "celebratePeace")
                    }
                  >
                    <option>Select Your Language</option>
                    {config?.celebratePeace?.map((item) => (
                      <option key={item.language} idx={item.link}>
                        {item.language}
                      </option>
                    ))}
                  </Select>
                </Box>
              </Flex>
            )}
            {timerDisplay1 && (
              <Box>
                <p sx={{ color: "#4A4A49" }}>Connecting in</p>
                <Timer
                  event={celebrateTime}
                  showVideo={() => setTimerDisplay1(false)}
                />
              </Box>
            )}
          </Box>
          <Box sx={{ borderLeft: "1px solid #d0bd63", pl: "3", pt: [3, 0, 0] }}>
            <Flex sx={{ justifyContent: ["space-between", null, "start"] }}>
              <span sx={{ fontSize: "14px" }}>9 pm local time</span>
            </Flex>
            <h1
              sx={{
                fontSize: ["22px", "30px", "36px"],
                fontWeight: "400",
                mt: "2",
                mb: "2",
              }}
            >
              Create Peace
            </h1>
            Guided meditation for world peace.
            {!timerDisplay2 && (
              <Flex
                sx={{
                  mt: [2, 2, 5],
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <Box
                  mr="3"
                  sx={{ flex: "1 1 1", fontWeight: "500", fontSize: "14px" }}
                >
                  WATCH HERE
                </Box>
                <Box mr="3" sx={{ flex: "1 1 1", alignItems: "center" }}>
                  <Select
                    defaultValue="Select Your Language"
                    sx={{
                      alignItems: "center",
                      cursor: "pointer",
                      borderRadius: 0,
                      width: "230px",
                      border: "solid 1px #a39864",
                      paddingLeft: ["8px", "16px", "16px"],
                      paddingRight: ["12px", "16px", "16px"],
                      fontSize: ["12px", "16px", "inherit"],
                      bg: "transparent",
                      outline: "none",
                    }}
                    onChange={(e) =>
                      triggerModel(e.target.value, "createPeace")
                    }
                  >
                    <option>Select Your Language</option>
                    {config?.createPeace?.map((item) => (
                      <option key={item.language} idx={item.link}>
                        {item.language}
                      </option>
                    ))}
                  </Select>
                </Box>
              </Flex>
            )}
            {timerDisplay2 && (
              <Box>
                <p sx={{ color: "#4A4A49" }}>Connecting in</p>
                <Timer
                  event={createTime}
                  showVideo={() => setTimerDisplay2(false)}
                />
              </Box>
            )}
          </Box>
        </Grid>
      </Box>
    </SectionWrapper>
  );
};

export default Events;
