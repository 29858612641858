/** @jsx jsx */
import { useState } from "react";
import { jsx, Box, Flex, Grid } from "theme-ui";

const Media = () => {
  const [active, setActive] = useState("Sep_21_2021");
  const mediaTitle = [
    {
      key: "Sep_21_2021",
      value: "Sep 21, 2021",
    },
    {
      key: "Sep_22_2021",
      value: "Sep 22, 2021",
    },
    {
      key: "Sep_23_2021",
      value: "Sep 23, 2021",
    },
    {
      key: "Sep_24_2021",
      value: "Sep 24, 2021",
    },
    {
      key: "Sep_25_2021",
      value: "Sep 25, 2021",
    },
    {
      key: "Sep_27_2021",
      value: "Sep 27, 2021",
    },
  ];

  const mediaHealines = {
    Sep_27_2021: [
      {
        name: "Ne India",
        text: "Connecting For Peace With Heartfulness On World Peace Day",
        link: "https://www.neindianews.com/connecting-for-peace-with-heartfulness-on-world-peace-day/",
      },
    ],
    Sep_25_2021: [
      {
        name: "The New Indian Express",
        text: "Are we doomed like dinosaurs, asks Shekhar Kapur - Heartfulness Peace Day",
        link: "http://indiamediamonitor.in/ViewImg.aspx?M5M9akD4BDlRTpx18JUCK3ZQWtBSChxhVITbmYo+uOOJkhSw3HpfkCj4O0Mz/uKehhcY0X75uSf7Y2/tcOJCcw==",
      },
      {
        name: "Assam Post",
        text: "Connecting for Peace with Heartfulness on World Peace Day",
        link: "http://indiamediamonitor.in/ViewImg.aspx?M5M9akD4BDlRTpx18JUCK+6YOF0KNDqN0dOOed+z36+RDRsODt3pg/fbVvxgaCh2iYKtPf5Rxisx9cZlgaD5Pg==",
      },
      {
        name: "Meghalaya Guardian",
        text: "Connecting for Peace with Heartfulness on World Peace Day",
        link: "http://indiamediamonitor.in/ViewImg.aspx?M5M9akD4BDlRTpx18JUCK+6YOF0KNDqN0dOOed+z368YuFAeEu0gvRc5JGLM3EU8cW66I0ivW5ANk4Iil1fBFQ==",
      },
      {
        name: "North East Times",
        text: "Connecting for Peace with Heartfulness on World Peace Day",
        link: "http://indiamediamonitor.in/ViewImg.aspx?M5M9akD4BDlRTpx18JUCK+6YOF0KNDqN0dOOed+z369L9FQxwW6NtyNF95ssP+b/2pXhMis2Z894U4JXtzzlgg==",
      },
      {
        name: "Suryakal",
        text: "Join with Heartfulness on world peace day for peace",
        link: "http://indiamediamonitor.in/ViewImg.aspx?M5M9akD4BDlRTpx18JUCK4PaIOW98MWnJMWZBidfScx+vMSmZI0XWvv52TWq6bAM1v6jeAvdFenBSw1W+Yl/Og==",
      },
    ],
    Sep_24_2021: [
      {
        name: "Indian Era",
        text: "Connecting for Peace with Heartfulness on World Peace Day",
        link: "http://indiamediamonitor.in/ViewImg.aspx?M5M9akD4BDlRTpx18JUCKy7TOUPr6cjrZKsgbXMXl/Hmpy27d1ldhW0vLSY8scvssM/gHwwaEQ5hDlasAKAfKg==",
      },
    ],
    Sep_23_2021: [
      {
        name: "Hybiz TV",
        text: "Connecting for Peace with Heartfulness on World Peace Day || Hybiz tv",
        link: "https://www.youtube.com/watch?v=PhfbSIzQRnc",
      },
    ],
    Sep_22_2021: [
      {
        name: "Yahoo News",
        text: "Over 1 million participate in virtual event on World Peace Day",
        link: "https://sg.news.yahoo.com/over-1-million-participate-virtual-204647591.html",
      },
      {
        name: "Lokmat",
        text: "Over 1 million participate in virtual event on World Peace Day",
        link: "https://english.lokmat.com/national/over-1-million-participate-in-virtual-event-on-world-peace-day/",
      },
      {
        name: "ANI News",
        text: "Over 1 million participate in virtual event on World Peace Day",
        link: "https://www.aninews.in/news/national/general-news/over-1-million-participate-in-virtual-event-on-world-peace-day20210922021647/",
      },
      {
        name: "Sify",
        text: "Over 1 million participate in virtual event on World Peace Day",
        link: "https://www.sify.com/news/over-1-million-participate-in-virtual-event-on-world-peace-day-news-national-vjvvaegahbjhc.html",
      },
      {
        name: "Zee5",
        text: "Over 1 million participate in virtual event on World Peace Day",
        link: "https://www.zee5.com/articles/over-1-million-participate-in-virtual-event-on-world-peace-day/amp",
      },
      {
        name: "National Herald",
        text: "Over 1 mn people connect on World Peace Day",
        link: "https://www.nationalheraldindia.com/international/over-1-mn-people-connect-on-world-peace-day",
      },
      {
        name: "Latestly",
        text: "Over 1 million participate in virtual event on World Peace Day",
        link: "https://www.latestly.com/agency-news/india-news-over-1-million-participate-in-virtual-event-on-world-peace-day-2873339.html/amp",
      },
      {
        name: "Business Fortnight",
        text: "Connecting for Peace with Heartfulness on World Peace Day",
        link: "https://businessfortnight.com/connecting-for-peace-with-heartfulness-on-world-peace-day/",
      },
      {
        name: "News Break",
        text: "Over 1 million participate in virtual event on World Peace Day",
        link: "https://www.newsbreak.com/news/2379500572077/1-million-participants-in-heartfulness-institute-event",
      },
      {
        name: "Mena FN",
        text: "Over 1 million participate in virtual event on World Peace Day",
        link: "https://www.newsbreak.com/news/2379500572077/1-million-participants-in-heartfulness-institute-event",
      },
      {
        name: "Big News Network",
        text: "Over 1 million participate in virtual event on World Peace Day",
        link: "https://www.bignewsnetwork.com/news/271248942/1-million-participants-in-heartfulness-institute-event",
      },
      {
        name: "APN News",
        text: "Connecting For Peace With Heartfulness On World Peace Day",
        link: "https://www.apnnews.com/connecting-for-peace-with-heartfulness-on-world-peace-day/",
      },
      {
        name: "Kalinga TV",
        text: "Over 1 million participate in virtual event on World Peace Day",
        link: "https://kalingatv.com/world/over-1-million-people-connect-on-world-peace-day/",
      },
      {
        name: "Web India",
        text: "Over 1 million participate in virtual event on World Peace Day",
        link: "https://news.webindia123.com/news/Articles/India/20210922/3823984.html",
      },
      {
        name: "AP 7AM",
        text: "Over 1 million participate in virtual event on World Peace Day",
        link: "https://www.ap7am.com/english-news-43666/over-1-mn-people-connect-on-world-peace-day",
      },
      {
        name: "Newkerala",
        text: "Over 1 million participate in virtual event on World Peace Day",
        link: "https://www.newkerala.com/news/2021/137103.htm",
      },
      {
        name: "The News Strike",
        text: "CONNECTING FOR PEACE WITH HEARTFULNESS ON WORLD PEACE DAY",
        link: "https://thenewsstrike.com/connecting-for-peace-with-heartfulness-on-world-peace-day/",
      },
      {
        name: "This Week India",
        text: "Connecting For Peace With Heartfulness On World Peace Day",
        link: "https://thisweekindia.news/connecting-for-peace-with-heartfulness-on-world-peace-day/",
      },
    ],
    Sep_21_2021: [
      {
        name: "Business Bytes",
        text: "Connecting for Peace with Heartfulness on World Peace Day",
        link: "https://thebusinessbytes.com/lifestyle/connecting-for-peace-with-heartfulness-on-world-peace-day/",
      },
      {
        name: "India Education Diary",
        text: "Connecting for Peace with Heartfulness on World Peace Day",
        link: "https://indiaeducationdiary.in/connecting-for-peace-with-heartfulness-on-world-peace-day/",
      },
      {
        name: "Can India",
        text: "Over 1 million participate in virtual event on World Peace Day",
        link: "https://www.canindia.com/over-1-mn-people-connect-on-world-peace-day/",
      },
      {
        name: "Social News XYZ",
        text: "Over 1 million participate in virtual event on World Peace Day",
        link: "https://www.socialnews.xyz/2021/09/21/over-1-mn-people-connect-on-world-peace-day/",
      },
      {
        name: "Business Gujarat News",
        text: "Connecting For Peace With Heartfulness On World Peace Day",
        link: "https://www.businessgujaratnews.com/connecting-for-peace-with-heartfulness-on-world-peace-day/",
      },
      {
        name: "Bold Outline",
        text: "Connecting For Peace With Heartfulness On World Peace Day",
        link: "https://boldoutline.in/connecting-for-peace-with-heartfulness-on-world-peace-day.html",
      },
      {
        name: "India News Republic",
        text: "Connecting For Peace With Heartfulness On World Peace Day",
        link: "https://indianewsrepublic.com/connecting-peace-with-all-your-heart-on-world-peace-day-education-in-india-latest-education-news-global-education-news/471321/",
      },
    ],
  };
  return (
    <Box>
      <Grid columns={[1, 3, 6]} sx={{ mb: "35px" }}>
        {mediaTitle.map((title) => (
          <span
            sx={{
              fontSize: "20px",
              fontWeight: 400,
              cursor: "pointer",
              padding: "6px",
              borderLeft: `${active === title.key && "2px solid #76AA3A"}`,
              textIndent: `${active === title.key && "5px"}`,
              boxShadow: `${active === title.key && "2px 2px 8px #0000002e"}`,
            }}
            onClick={() => setActive(title.key)}
            role="button"
            tabIndex={0}
            onKeyDown={() => setActive(title.key)}
          >
            {title.value}
          </span>
        ))}
      </Grid>
      {mediaHealines[active].map((item) => (
        <Box sx={{ width: "100%" }}>
          <Flex sx={{ justifyContent: "space-between", width: "100%" }}>
            <h5 sx={{ flex: 1, color: "#76AA3A" }}>{item.name}</h5>
            <a
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                alignSelf: "center",
                flex: 3,
                textDecoration: "none",
                cursor: "pointer",
                color: "#000",
              }}
            >
              {item.text}
            </a>
          </Flex>
          <hr sx={{ width: "100%" }} />
        </Box>
      ))}
    </Box>
  );
};

export default Media;
