/** @jsx jsx */
import { jsx, Box, Flex } from "theme-ui";
import { StaticImage } from "gatsby-plugin-image";
import { useState, useEffect } from "react";

const IFPBanner = () => {
  const [page, setPage] = useState(0);
  useEffect(() => {
    const interval = setInterval(function LoadPage() {
      setPage((pagex) => (pagex === 6 ? 0 : pagex + 1));
    }, 5000);
    return () => clearInterval(interval);
  }, []);
  const iterate = [0, 1, 2, 3, 4, 5];
  const sliderImages = [
    <StaticImage
      src="../assets/img/Quotes1.png"
      alt="Quotes"
      sx={{ width: "100%" }}
      placeholder="blurred"
    />,
    <StaticImage
      src="../assets/img/Quotes2.png"
      alt="Quotes"
      sx={{ width: "100%" }}
      placeholder="blurred"
    />,
    <StaticImage
      src="../assets/img/Quotes3.png"
      alt="Quotes"
      sx={{ width: "100%" }}
      placeholder="blurred"
    />,
    <StaticImage
      src="../assets/img/Quotes4.png"
      alt="Quotes"
      sx={{ width: "100%" }}
      placeholder="blurred"
    />,
    <StaticImage
      src="../assets/img/Quotes5.png"
      alt="Quotes"
      sx={{ width: "100%" }}
      placeholder="blurred"
    />,
    <StaticImage
      src="../assets/img/Quotes6.png"
      alt="Quotes"
      sx={{ width: "100%" }}
      placeholder="blurred"
    />,
    <StaticImage
      src="../assets/img/Quotes7.png"
      alt="Quotes"
      sx={{ width: "100%" }}
      placeholder="blurred"
    />,
  ];

  return (
    <Box sx={{ position: "relative" }}>
      {sliderImages[page]}
      <Flex
        sx={{
          position: "absolute",
          bottom: ["10px", "30px"],
          left: "60px",
        }}
      >
        {iterate &&
          iterate.map((item) => (
            <Box
              key={item}
              onClick={() => setPage(item)}
              sx={{
                variant: "layout.dot",
                width: `14px`,
                height: `14px`,
                opacity: `0.7`,
                cursor: `pointer`,
              }}
            />
          ))}
      </Flex>
    </Box>
  );
};

export default IFPBanner;
