/** @jsx jsx */
import { jsx, Box, Flex } from "theme-ui";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import React, { useState, useEffect } from "react";

const Slider = (props) => {
  const { imgSrc, title, containerStyle, arrowStyle, noOfImgPerSlider } = props;

  const [imgData, setImageData] = useState([]);
  const [imgPerSlider] = useState(noOfImgPerSlider);
  const [index, setIndex] = useState(0);
  const initalImage = 0;
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  const totalSlides = index === -1 ? 5 : index + 2;
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const breakPoints = [576, 768, 992, 1200];
    const breakValue = breakPoints.find((val) => windowSize.width < val);
    const indexVal = breakPoints.indexOf(breakValue);
    setIndex(indexVal);
  }, [windowSize]);

  useEffect(() => {
    setImageData(imgSrc);
  }, [imgSrc]);

  const farwardSwap = () => {
    if (imgData.length > 0) {
      setImageData((img) => {
        const firstImg = img.shift();
        return img.concat([firstImg]);
      });
    }
  };
  const backwardSwap = () => {
    if (imgData.length > 0) {
      setImageData((data) => {
        const firstImg = data.pop();
        return [firstImg].concat(data);
      });
    }
  };

  return (
    <React.Fragment>
      <Flex sx={{ alignItems: "center" }}>
        <Box sx={{ flex: "1 1 auto" }}>{title}</Box>
        <Flex sx={{ ...arrowStyle }}>
          <FontAwesomeIcon
            sx={{ mr: 3, cursor: "pointer" }}
            color="#76AA3A"
            icon={faArrowLeft}
            onClick={() => farwardSwap()}
          />
          <FontAwesomeIcon
            sx={{ cursor: "pointer" }}
            color="#76AA3A"
            icon={faArrowRight}
            onClick={() => backwardSwap()}
          />
        </Flex>
      </Flex>
      <Flex sx={{ alignItems: "center", justifyContent: "space-between" }}>
        {imgData.slice(initalImage, imgPerSlider || totalSlides).map((img) => (
          <Box
            sx={{
              ...containerStyle,
              textAlign: "center",
            }}
            key={img}
          >
            {img}
          </Box>
        ))}
      </Flex>
    </React.Fragment>
  );
};

Slider.defaultProps = {
  imgSrc: [],
  arrowStyle: {},
  title: "",
  containerStyle: {},
  noOfImgPerSlider: 0,
};

Slider.propTypes = {
  imgSrc: PropTypes.shape([]),
  title: PropTypes.string,
  containerStyle: PropTypes.shape({}),
  arrowStyle: PropTypes.shape({}),
  noOfImgPerSlider: PropTypes.number,
};

export default React.memo(Slider);
