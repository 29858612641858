/** @jsx jsx */
import { StaticImage } from "gatsby-plugin-image";
import { jsx, Box, Grid, Flex } from "theme-ui";
import React from "react";

const MOPAuthors = () => {
  return (
    <React.Fragment>
      <Grid columns={[1, 1, 2]} gap={2} sx={{ justifyContent: "left" }}>
        <Flex
          sx={{
            alignItems: "center",
            justifyContent: ["flex-start", null, "center"],
            alignSelf: "flex-start",
            mb: 2,
          }}
        >
          <StaticImage
            src="../assets/img/Mask.png"
            sx={{
              width: ["100", null, "200"],
              height: ["100", null, "200"],
              flexShrink: 0,
              borderRadius: "50%",
            }}
            alt="Logo"
            placeholder="blurred"
          />
          <Box ml="4">
            <span sx={{ color: "#0082BE" }}>
              <h1 sx={{ margin: "0", fontSize: "26px !important" }}>Daaji</h1>
            </span>
            <span sx={{ color: "#4A4A49" }}>Heartfulness Guide</span>
          </Box>
        </Flex>
        <Flex
          sx={{
            alignItems: "center",
            justifyContent: ["flex-start", null, "center"],
            alignSelf: "flex-start",
            mb: 2,
          }}
        >
          <StaticImage
            src="../assets/img/jeremy-gilley-vertical.png"
            sx={{
              width: ["100", null, "200"],
              height: ["100", null, "200"],
              flexShrink: 0,
              borderRadius: "50%",
            }}
            alt="Logo"
            placeholder="blurred"
          />
          <Box ml="4">
            <span sx={{ color: "#0082BE" }}>
              <h1 sx={{ margin: "0", fontSize: "26px !important" }}>
                Jeremy Gilley
              </h1>
            </span>
            <span sx={{ color: "#4A4A49" }}>Founder, Peace One Day</span>
          </Box>
        </Flex>
      </Grid>
      <Grid
        gap={2}
        sx={{
          textAlign: "right",
          justifyContent: "right",
          mt: ["4px", null, "16px"],
        }}
        columns={[1, 1, 2]}
      >
        <Flex
          sx={{
            alignItems: "center",
            justifyContent: ["flex-end", null, "center"],
            alignSelf: "flex-start",
            mb: 2,
          }}
        >
          <Box mr="3">
            <span sx={{ color: "#0082BE" }}>
              <h1 sx={{ margin: "0", fontSize: "26px !important" }}>
                Kabir Bedi
              </h1>
            </span>
            <span sx={{ color: "#4A4A49" }}>Renowned Actor and Author</span>
          </Box>
          <StaticImage
            src="../assets/img/Mask-2.png"
            sx={{
              width: ["100", null, "200"],
              height: ["100", null, "200"],
              flexShrink: 0,
              borderRadius: "50%",
            }}
            alt="Logo"
            placeholder="blurred"
          />
        </Flex>
        <Flex
          sx={{
            alignItems: "center",
            justifyContent: ["flex-end", null, "center"],
            alignSelf: "flex-start",
            mb: 2,
          }}
        >
          <Box mr="3">
            <span sx={{ color: "#0082BE" }}>
              <h1 sx={{ margin: "0", fontSize: "26px !important" }}>
                Shekhar Kapur
              </h1>
            </span>
            <span sx={{ color: "#4A4A49" }}>
              Award-winning Director, Actor and Author
            </span>
          </Box>
          <StaticImage
            src="../assets/img/shekar-kaur-speaker-img.png"
            sx={{
              width: ["100", null, "200"],
              height: ["100", null, "200"],
              flexShrink: 0,
              borderRadius: "50%",
            }}
            alt="Logo"
            placeholder="blurred"
          />
        </Flex>
      </Grid>
    </React.Fragment>
  );
};

export default MOPAuthors;
