/** @jsx jsx */
import {
  // faArrowLeft,
  // faArrowRight,
  faPowerOff,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StaticImage } from "gatsby-plugin-image";
import React, { useState, useCallback } from "react";
import { jsx, Box, Flex } from "theme-ui";
import VideoModel from "../component/VideoModel";
import SectionWrapper from "../Layout/SectionLayout";
import IFPBanner from "./IFPBanner";

function InspirationsForPeace() {
  // const [index, setIndex] = useState(0);
  // const initalImage = 0;
  // const [windowSize, setWindowSize] = useState({
  //   width: undefined,
  //   height: undefined,
  // });
  const [modal, setModal] = useState(false);
  const [id, setId] = useState("");
  const triggerModel = useCallback((Yid) => {
    setId(Yid);
    setModal(true);
  }, []);
  // const videos = [
  //   <StaticImage
  //     src="../assets/img/video-banner-1.png"
  //     height={220}
  //     alt="googlePlay"
  //     placeholder="blurred"
  //     sx={{
  //       cursor: "pointer",
  //     }}
  //     onClick={() => triggerModel("hjCF3uPKR6I")}
  //   />,
  //   <StaticImage
  //     src="../assets/img/video-banner-1.png"
  //     height={220}
  //     alt="googlePlay"
  //     placeholder="blurred"
  //     sx={{
  //       ml: "8px",
  //       cursor: "pointer",
  //     }}
  //     onClick={() => triggerModel("CYxgbJiwU7k")}
  //   />,
  //   <StaticImage
  //     src="../assets/img/video-banner-1.png"
  //     height={220}
  //     alt="googlePlay"
  //     sx={{
  //       ml: "8px",
  //       cursor: "pointer",
  //     }}
  //     onClick={() => triggerModel("3jec_9ECvtc")}
  //   />,
  // ];
  // const [imgData, setImageData] = useState(videos);
  // const totalSlides = index === -1 ? 5 : index + 1;
  // useEffect(() => {
  //   function handleResize() {
  //     setWindowSize({
  //       width: window.innerWidth,
  //       height: window.innerHeight,
  //     });
  //   }
  //   window.addEventListener("resize", handleResize);
  //   handleResize();
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);

  // useEffect(() => {
  //   const breakPoints = [576, 768, 992, 1200];
  //   const breakValue = breakPoints.find((val) => windowSize.width < val);
  //   const indexVal = breakPoints.indexOf(breakValue);
  //   setIndex(indexVal);
  // }, [windowSize]);

  // const farwardSwap = () => {
  //   if (imgData.length > 0) {
  //     setImageData((img) => {
  //       const firstImg = img.shift();
  //       return img.concat([firstImg]);
  //     });
  //   }
  // };
  // const backwardSwap = () => {
  //   if (imgData.length > 0) {
  //     setImageData((data) => {
  //       const firstImg = data.pop();
  //       return [firstImg].concat(data);
  //     });
  //   }
  // };
  return (
    <React.Fragment>
      {modal && <VideoModel modalShow={() => setModal(false)} id={id} />}
      <SectionWrapper
        sectionStyles={{
          pt: ["20px", "15px", "15px", "25px"],
          pb: ["10px", "15px", "15px", "15px"],
        }}
      >
        <Box
          sx={{
            fontSize: ["1.4em", "1.7em", "2em"],
            fontWeight: "bolder",
            alignItems: "center",
            color: "#FFD600",
          }}
          mb="3"
        >
          Inspirations f
          <FontAwesomeIcon color="#76AA3A" icon={faPowerOff} size="xs" />r Peace
        </Box>
      </SectionWrapper>
      {/* <Box sx={{ margin: "0 auto", width: "80%" }}>
        <Flex
          sx={{
            justifyContent: "flex-end",
            display: ["flex", null, "flex"],
          }}
        >
          <FontAwesomeIcon
            sx={{ mr: 3, cursor: "pointer" }}
            color="#76AA3A"
            icon={faArrowLeft}
            onClick={() => farwardSwap()}
          />
          <FontAwesomeIcon
            sx={{ cursor: "pointer" }}
            color="#76AA3A"
            icon={faArrowRight}
            onClick={() => backwardSwap()}
          />
        </Flex>
        <Flex
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            my: 4,
          }}
        >
          {imgData && imgData.slice(initalImage, totalSlides).map((vid) => vid)}
        </Flex> */}
      {/* </Flex> */}
      {/* </Box> */}
      <Flex sx={{ justifyContent: "center", mb: 4 }}>
        <StaticImage
          src="../assets/img/popupImg01.png"
          alt="googlePlay"
          placeholder="blurred"
          sx={{
            cursor: "pointer",
            width: "350px",
            height: "196px",
          }}
          onClick={() =>
            triggerModel("https://www.youtube.com/embed/eGPx5q-NJso")
          }
        />
      </Flex>
      <IFPBanner />
    </React.Fragment>
  );
}

export default React.memo(InspirationsForPeace);
