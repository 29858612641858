/** @jsx jsx */
import { jsx, Box, Flex } from "theme-ui";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import scrollTo from "gatsby-plugin-smoothscroll";
import Banner from "./component/Banner";
import MessengerOfPeace from "./content/MessengersOfPeace";
import Footer from "./component/Footer";
import Header from "./component/Header";
import SectionWrapper from "./Layout/SectionLayout";
import PlanetPeace from "./content/PlanetPeace";
import InspirationsForPeace from "./content/InspirationsForPeace";
import PartnerOfPeace from "./content/PartnerOfPeace";
import MOPSlider from "./content/MOPSlider";
import Heartintune from "./content/Heartintune";
import Events from "./content/Events";
import PeaceDayActivities from "./content/PeaceDayActivities";
import Media from "./component/Media";
import PressRelease from "./component/PressRelease";

const PeaceDay = () => {
  const [user] = useState({});

  const HUMANContent = [
    { fw: "Heart", s: "the power of love to conquer all." },
    { fw: "Unite", s: "the power of togetherness." },
    { fw: "Meditate ", s: "the power of inner awareness." },
    { fw: "Action ", s: "the power of thought for change." },
    { fw: "Nature ", s: "the power of regeneration." },
  ];

  return (
    <section
      sx={{
        backgroundColor: "#ffff",
        color: "#4A4A49",
        fontFamily:
          "gotham, system-ui, -apple-system, BlinkMacSystemFont, sans-serif",
      }}
    >
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
        title="International Peace Day 2021"
      />
      <Header onMenuClick={(e) => scrollTo(`#${e}`)} />
      <Banner />
      <SectionWrapper
        sectionStyles={{
          padding: "0px",
          position: "relative",
          zIndex: "99",
          backgroundColor: "#fff",
          margin: [0, null, "0 auto"],
          maxWidth: "100%",
        }}
        boxStyle={{
          mx: [0, null, "auto"],
          px: [0, null, "15px"],
        }}
      >
        <Flex sx={{ flexDirection: ["column-reverse", null, "row"] }}>
          <Box
            p="5"
            sx={{
              padding: [
                "10px 4px 50px 15px",
                "25px 60px 50px 15px",
                "25px 64px 50px 0",
              ],
            }}
          >
            <h1 sx={{ fontSize: ["22px", "30px", "35px"], color: "#76AA3A" }}>
              Be U, Be Peace
            </h1>
            <Box mt="2" mb="3">
              Connect with people all around the world in a simple guided
              meditation for Peace.
            </Box>
            {HUMANContent.map((text) => (
              <Box key={text.fw}>
                <span
                  sx={{
                    fontSize: ["26px", "26px", "32px"],
                    fontWeight: "500",
                    color: "#76AA3A",
                  }}
                >
                  {text.fw.substring(0, 1)}
                </span>
                <span
                  sx={{
                    fontWeight: "500",
                    letterSpacing: "1.5px",
                    color: "#76AA3A",
                  }}
                >
                  {text.fw.substring(1)}
                </span>{" "}
                {text.s}
              </Box>
            ))}
          </Box>
        </Flex>
      </SectionWrapper>
      <Events />
      <SectionWrapper
        id="planetPeace"
        sectionStyles={{ pb: ["10px", "30px", "20px"] }}
      >
        <PlanetPeace user={user} />
      </SectionWrapper>
      <SectionWrapper
        sectionStyles={{
          pt: ["15px", "30px", "10px"],
          pb: ["0px", "30px", "40px"],
        }}
      >
        <MessengerOfPeace />
      </SectionWrapper>
      <MOPSlider />
      <InspirationsForPeace />
      <Heartintune />
      <SectionWrapper id="partners">
        <PartnerOfPeace />
      </SectionWrapper>
      <SectionWrapper>
        <Box
          sx={{
            fontSize: ["22px", "25px", "30px"],
            alignItems: "center",
            color: "#76AA3A",
            marginBottom: ["18px", "22px", "26px"],
            fontWeight: "500",
          }}
        >
          Media
        </Box>
        <Media />
      </SectionWrapper>
      <SectionWrapper>
        <Box
          sx={{
            fontSize: ["22px", "25px", "30px"],
            alignItems: "center",
            color: "#76AA3A",
            marginBottom: ["18px", "22px", "26px"],
            fontWeight: "500",
          }}
        >
          Press
        </Box>
        <PressRelease />
      </SectionWrapper>
      <SectionWrapper sectionStyles={{ bg: "#D9E3EB" }}>
        <PeaceDayActivities />
      </SectionWrapper>
      <Footer />
    </section>
  );
};

export default React.memo(PeaceDay);
