/** @jsx jsx */
import { jsx, Box, Flex, Badge, Button } from "theme-ui";
import { StaticImage } from "gatsby-plugin-image";
// import { useState } from "react";
// import { Modal } from "../../../components/Modal";

// function DisplayModel() {
//   return (
//     <Modal
//       title=""
//       style_container={{
//         width: "100%",
//       }}
//       style_main={{
//         bg: "transparent",
//         top: 0,
//         left: 0,
//         transform: "none",
//       }}
//     >
//       <div
//         style={{
//           position: "relative",
//         }}
//       >
//         <Box
//           onClick={() => setModal(false)}
//           sx={{
//             textAlign: "end",
//             cursor: "pointer",
//             mb: 3,
//             color: "#fff",
//             position: "absolute",
//             top: ["160px", "185px", "115px", "70px"],
//             left: ["310px", "540px", "700px", "960px"],
//           }}
//         >
//           X
//         </Box>
//         <iframe
//           src="https://www.loom.com/embed/8f3f2fb29de542baaff0359cc78f7f6c"
//           frameBorder="0"
//           title="Heartfulness"
//           webkitAllowFullScreen="true"
//           mozAllowFullScreen="true"
//           allowFullScreen="true"
//           sx={{
//             position: "absolute",
//             top: ["200px", "225px", "150px", "100px"],
//             left: ["30px", "160px", "220px", "280px"],
//             width: ["300px", "400px", "500px", "700px"],
//             height: ["230px", "300px", "400px"],
//           }}
//         />
//       </div>
//     </Modal>
//   );
// }

function PeaceDayActivities() {
  // const [modal, setModal] = useState(false);
  return (
    <Box>
      {/* {modal && <DisplayModel />} */}
      <h2 sx={{ textAlign: "center" }}>
        <span sx={{ color: "#76AA3A" }}>Peace Day</span> activities everywhere
      </h2>
      <Flex
        sx={{
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: ["20px", null, null, 0],
        }}
      >
        <Box sx={{ border: "1px solid #4A4A49", padding: "8px" }}>
          <StaticImage
            src="../assets/img/acti2.png"
            sx={{
              height: "160px",
              // maxWidth: ["120px", "150px"],
              width: "300px",
              m: "10px",
            }}
            placeholder="blurred"
            alt="Logo"
          />
          <Box sx={{ p: "20px", width: "330px" }}>
            <Badge
              sx={{
                letterSpacing: "1.5px",
                backgroundColor: "#0082BE",
                padding: "4px 8px",
                fontSize: "12px",
                fontWeight: "300",
                lineHeight: "1.5",
                ml: 2,
              }}
              variant="accent"
            >
              LIVE
            </Badge>{" "}
            Webinar
            <h6
              sx={{
                fontSize: ["28px", "30px"],
                letterSpacing: 0,
                color: "#4A4A49",
                margin: 0,
                padding: "6px 0",
                fontWeight: 400,
              }}
            >
              What is the
            </h6>
            <h3
              sx={{
                fontWeight: 600,
                fontSize: "35px",
                letterSpacing: 0,
                lineHeight: "47px",
                color: "#4A4A49",
                margin: 0,
                padding: 0,
              }}
            >
              Future of
            </h3>
            <h3
              sx={{
                fontWeight: 600,
                fontSize: "35px",
                letterSpacing: 0,
                color: "#76AA3A",
                margin: 0,
                padding: 0,
              }}
            >
              Management?
            </h3>
            <p sx={{ fontSize: "18px", fontWeight: 500 }}>
              by Dr. Ichak Kalderon Adizes
            </p>
            <p sx={{ fontSize: "14px" }}>
              <b>on 21 September, 3 pm EST.</b>
            </p>
            <p sx={{ fontSize: "15px", padding: 0, marginTop: "6px" }}>
              The International Day of Peace
            </p>
            {/* <Link
              sx={{
                display: "inline-block",
                padding: "10px 30px",
                color: "#ffffff",
                background: "#76AA3A",
                fontSize: "16px",
                textDecoration: "none",
                borderRadius: "3px",
                ":hover": {
                  color: "#fff",
                },
              }}
              to="/peaceday-2021/corporate-webinar"
            >
              Know More
            </Link> */}
          </Box>
        </Box>
        <Box sx={{ border: "1px solid #4A4A49", padding: "8px" }}>
          <StaticImage
            src="../assets/img/acti1.png"
            sx={{
              height: "160px",
              // maxWidth: ["120px", "150px"],
              width: "300px",
              m: "10px",
            }}
            placeholder="blurred"
            alt="Logo"
          />
          <Box sx={{ p: "20px", width: "330px" }}>
            <StaticImage
              src="../assets/img/PeaceLogo.png"
              sx={{
                height: ["auto", "auto", "fit-content"],
                // maxWidth: ["120px", "150px"],
                width: "100px",
              }}
              placeholder="blurred"
              alt="Logo"
            />
            <h1 sx={{ m: 0, fontSize: "36px", mt: "30px" }}>
              A Day of <b sx={{ color: "#76AA3A" }}>Peace</b>
            </h1>
            <p
              sx={{
                fontSize: "18px",
                fontWeight: 500,
                width: "300px",
                mt: "33px",
                mb: "95px",
              }}
            >
              Free E-Book for celebrating Peace Day in your school
            </p>
            <Flex sx={{ justifyContent: "space-between" }}>
              <span
                sx={{
                  display: "inline-block",
                  padding: "10px 30px",
                  color: "#ffffff",
                  background: "#76AA3A",
                  fontSize: "16px",
                  textDecoration: "none",
                  borderRadius: "3px",
                  cursor: "pointer",
                }}
                role="button"
                tabIndex={0}
                onClick={() =>
                  window.open(
                    "https://cdn-prod.heartfulness.org/hfn/ipd/2021/Peace-ebook-India-School-Connect-Sept-2021.pdf"
                  )
                }
                onKeyDown={() =>
                  window.open(
                    "https://cdn-prod.heartfulness.org/hfn/ipd/2021/Peace-ebook-India-School-Connect-Sept-2021.pdf"
                  )
                }
              >
                India
              </span>
              {/* <Link
                sx={{
                  display: "inline-block",
                  padding: "10px 30px",
                  color: "#ffffff",
                  background: "#76AA3A",
                  fontSize: "16px",
                  textDecoration: "none",
                  borderRadius: "3px",
                  ":hover": {
                    color: "#fff",
                  },
                }}
                to="/peaceday-2021/ebooks"
              >
                International
              </Link> */}
            </Flex>
          </Box>
        </Box>
        <Box sx={{ border: "1px solid #4A4A49", padding: "8px" }}>
          <StaticImage
            src="../assets/img/acti3.png"
            sx={{
              height: "160px",
              // maxWidth: ["120px", "150px"],
              width: "300px",
              m: "10px",
            }}
            placeholder="blurred"
            alt="Logo"
          />
          <Box sx={{ p: "20px", width: "330px" }}>
            <h1 sx={{ m: 0, fontSize: "36px", mt: "30px" }}>
              Communities of <span sx={{ color: "#76AA3A" }}>Peace</span>
            </h1>
            <p
              sx={{
                fontSize: "18px",
                fontWeight: 500,
                width: "300px",
                mt: "33px",
                mb: "95px",
              }}
            >
              A Global commUnity of communities
            </p>
            <Button
              sx={{
                display: "inline-block",
                padding: "10px 30px",
                color: "#ffffff",
                background: "#76AA3A",
                fontSize: "16px",
                textDecoration: "none",
                borderRadius: "3px",
                cursor: "pointer",
              }}
              // onClick={() => setModal(true)}
            >
              Know More
            </Button>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
}

export default PeaceDayActivities;
