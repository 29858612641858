/** @jsx jsx */
import { jsx, Box } from "theme-ui";
import { faPowerOff } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MOPAuthors from "../component/MOPAuthors";

function MessengerOfPeace() {
  return (
    <Box>
      <h1
        sx={{
          color: "#0082BE",
          fontSize: ["1.4em", "1.7em", "2em"],
          m: "30px 0",
        }}
      >
        Messengers{" "}
        <FontAwesomeIcon color="#76AA3A" icon={faPowerOff} size="xs" />f Peace
      </h1>
      <MOPAuthors />
    </Box>
  );
}

export default MessengerOfPeace;
