/** @jsx jsx */
import { StaticImage } from "gatsby-plugin-image";
import { useState } from "react";
import { Dialog, DialogType } from "office-ui-fabric-react";
import { jsx, Box, Flex, Image } from "theme-ui";
import press1 from "../assets/img/press1.jpg";
import press2 from "../assets/img/press2.jpg";
import press3 from "../assets/img/press3.jpg";
import press4 from "../assets/img/press4.jpg";
import press5 from "../assets/img/press5.jpg";
import press6 from "../assets/img/press6.jpg";
import press7 from "../assets/img/press7.jpg";
import press8 from "../assets/img/press8.jpg";
import press9 from "../assets/img/press9.jpg";
import press10 from "../assets/img/press10.jpg";
import press11 from "../assets/img/press11.jpg";
import press12 from "../assets/img/press12.jpg";
import press13 from "../assets/img/press13.jpg";
import press14 from "../assets/img/press14.jpg";
import press15 from "../assets/img/press15.jpg";
import press16 from "../assets/img/press16.jpg";
import press17 from "../assets/img/press17.jpg";
import press18 from "../assets/img/press18.jpg";
import press19 from "../assets/img/press19.jpg";
import { appsDialogStyles } from "../../../utils/dialogStyles";

const PressRelease = () => {
  const [modal, showModal] = useState(false);
  const [id, setId] = useState(null);
  const TriggerModel = (imgId) => {
    setId(imgId);
    showModal(true);
  };
  return (
    <Box>
      <Flex sx={{ gap: "20px", flexDirection: ["column", null, "row"] }}>
        <Box sx={{ flexBasis: "30%" }}>
          <StaticImage
            src="../assets/img/press1.jpg"
            alt="Press_release"
            sx={{ width: "100%", my: "12.5px", cursor: "pointer" }}
            placeholder="blurred"
            onClick={() => TriggerModel(press1)}
          />
          <StaticImage
            src="../assets/img/press2.jpg"
            alt="Press_release"
            sx={{ width: "100%", my: "10px", cursor: "pointer" }}
            placeholder="blurred"
            onClick={() => TriggerModel(press2)}
          />
          <StaticImage
            src="../assets/img/press3.jpg"
            alt="Press_release"
            sx={{ width: "100%", my: "10px", pt: "40px", cursor: "pointer" }}
            placeholder="blurred"
            onClick={() => TriggerModel(press3)}
          />
          <StaticImage
            src="../assets/img/press10.jpg"
            alt="Press_release"
            sx={{ width: "100%", my: "10px", cursor: "pointer" }}
            placeholder="blurred"
            onClick={() => TriggerModel(press10)}
          />
        </Box>
        <Box sx={{ flexBasis: "20%" }}>
          <StaticImage
            src="../assets/img/press4.jpg"
            alt="Press_release"
            sx={{ width: "100%", my: "10px", pt: "40px", cursor: "pointer" }}
            placeholder="blurred"
            onClick={() => TriggerModel(press4)}
          />
          <StaticImage
            src="../assets/img/press6.jpg"
            alt="Press_release"
            sx={{ width: "100%", my: "10px", cursor: "pointer" }}
            placeholder="blurred"
            onClick={() => TriggerModel(press6)}
          />
          <StaticImage
            src="../assets/img/press18.jpg"
            alt="Press_release"
            sx={{
              width: "100%",
              my: "10px",
              pb: "46px",
              pt: 0,
              cursor: "pointer",
            }}
            placeholder="blurred"
            onClick={() => TriggerModel(press18)}
          />
        </Box>
        <Box sx={{ flexBasis: "20%" }}>
          <StaticImage
            src="../assets/img/press11.jpg"
            alt="Press_release"
            sx={{ width: "100%", my: "10px", pt: "40px", cursor: "pointer" }}
            placeholder="blurred"
            onClick={() => TriggerModel(press11)}
          />
          <StaticImage
            src="../assets/img/press12.jpg"
            alt="Press_release"
            sx={{ width: "100%", my: "10px", cursor: "pointer" }}
            placeholder="blurred"
            onClick={() => TriggerModel(press12)}
          />
          <StaticImage
            src="../assets/img/press13.jpg"
            alt="Press_release"
            sx={{ width: "100%", my: "10px", cursor: "pointer" }}
            placeholder="blurred"
            onClick={() => TriggerModel(press13)}
          />
          <StaticImage
            src="../assets/img/press17.jpg"
            alt="Press_release"
            sx={{ width: "100%", my: "10px", cursor: "pointer" }}
            placeholder="blurred"
            onClick={() => TriggerModel(press17)}
          />
          <StaticImage
            src="../assets/img/press19.jpg"
            alt="Press_release"
            sx={{ width: "100%", my: "10px", cursor: "pointer" }}
            placeholder="blurred"
            onClick={() => TriggerModel(press19)}
          />
          <StaticImage
            src="../assets/img/press15.jpg"
            alt="Press_release"
            sx={{ width: "100%", my: "10px", cursor: "pointer" }}
            placeholder="blurred"
            onClick={() => TriggerModel(press15)}
          />
        </Box>
        <Box sx={{ flexBasis: "30%" }}>
          <StaticImage
            src="../assets/img/press7.jpg"
            alt="Press_release"
            sx={{ width: "100%", my: "10px", cursor: "pointer" }}
            placeholder="blurred"
            onClick={() => TriggerModel(press7)}
          />
          <StaticImage
            src="../assets/img/press8.jpg"
            alt="Press_release"
            sx={{ width: "100%", my: "10px", cursor: "pointer" }}
            placeholder="blurred"
            onClick={() => TriggerModel(press8)}
          />
          <StaticImage
            src="../assets/img/press9.jpg"
            alt="Press_release"
            sx={{ width: "100%", my: "10px", cursor: "pointer" }}
            placeholder="blurred"
            onClick={() => TriggerModel(press9)}
          />
          <StaticImage
            src="../assets/img/press14.jpg"
            alt="Press_release"
            sx={{ width: "100%", my: "10px", cursor: "pointer" }}
            placeholder="blurred"
            onClick={() => TriggerModel(press14)}
          />
          <StaticImage
            src="../assets/img/press5.jpg"
            alt="Press_release"
            sx={{ width: "100%", my: "10px", cursor: "pointer" }}
            placeholder="blurred"
            onClick={() => TriggerModel(press5)}
          />
          <StaticImage
            src="../assets/img/press16.jpg"
            alt="Press_release"
            sx={{ width: "100%", my: "10px", cursor: "pointer" }}
            placeholder="blurred"
            onClick={() => TriggerModel(press16)}
          />
        </Box>
      </Flex>
      <Dialog
        isOpen={modal}
        type={DialogType.close}
        onDismiss={() => showModal(false)}
        title=""
        subText=""
        isBlocking
        closeButtonAriaLabel="Close"
        sx={{ bg: "none" }}
        styles={appsDialogStyles.medium}
      >
        <Flex
          sx={{
            flexDirection: "column",
            my: 5,
            maxWidth: ["100%", "400px", "500px"],
            height: "auto",
            margin: "6% auto",
          }}
        >
          <Box
            onClick={() => showModal(false)}
            sx={{ textAlign: "end", cursor: "pointer", my: 3, color: "#fff" }}
          >
            X
          </Box>
          <Image
            src={id}
            alt="Press_release"
            sx={{ maxWidth: "500px", width: "fit-content", m: "10px auto" }}
            placeholder="blurred"
          />
        </Flex>
      </Dialog>
    </Box>
  );
};

export default PressRelease;
