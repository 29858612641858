/** @jsx jsx */
import { jsx, Box, Flex } from "theme-ui";
import { StaticImage } from "gatsby-plugin-image";
import React, { useState } from "react";

const MOPSlider = () => {
  const [toggle, setToggle] = useState(true);
  return (
    <Box sx={{ bg: "#F4F4F4", width: "100%" }}>
      <Box sx={{ width: ["90%", "85%", "70%"], margin: "0 auto" }}>
        <Box sx={{ position: "relative", bg: "#F4F4F4", mt: 4 }}>
          {toggle && (
            <Flex
              sx={{
                justifyContent: ["left", null, "space-between"],
                flexDirection: ["column", null, "row"],
                alignItems: "flex-start",
              }}
            >
              <Box>
                <Flex
                  sx={{
                    alignItems: "center",
                    padding: "25px",
                    justifyContent: "center",
                  }}
                >
                  <StaticImage
                    src="../assets/img/pv-sindhu.png"
                    sx={{
                      width: "120px",
                      height: "120px",
                      flexShrink: 0,
                      borderRadius: "50%",
                    }}
                    alt="Logo"
                    placeholder="blurred"
                  />
                  <Box ml="3">
                    <span sx={{ color: "#0082BE" }}>
                      <h3
                        sx={{
                          margin: "0",
                          fontSize: "18px",
                          fontWeight: "500",
                        }}
                      >
                        PV Sindhu
                      </h3>
                    </span>
                    <span sx={{ color: "#4A4A49", fontSize: "16px" }}>
                      Olympian & World Champion Badminton Player
                    </span>
                  </Box>
                </Flex>
              </Box>
              <Box>
                <Flex
                  sx={{
                    alignItems: "center",
                    padding: "25px",
                    justifyContent: "center",
                  }}
                >
                  <StaticImage
                    src="../assets/img/Amish-tripati-2.png"
                    sx={{
                      width: "120px",
                      height: "120px",
                      flexShrink: 0,
                      borderRadius: "50%",
                    }}
                    alt="Logo"
                    placeholder="blurred"
                  />
                  <Box ml="3">
                    <span sx={{ color: "#0082BE" }}>
                      <h3
                        sx={{
                          margin: "0",
                          fontSize: "18px",
                          fontWeight: "500",
                        }}
                      >
                        Amish Tripathi
                      </h3>
                    </span>
                    <span sx={{ color: "#4A4A49", fontSize: "16px" }}>
                      Writer
                    </span>
                  </Box>
                </Flex>
              </Box>
            </Flex>
          )}
          {!toggle && (
            <Flex
              sx={{
                justifyContent: ["left", null, "space-between"],
                flexDirection: ["column", null, "row"],
                alignItems: "flex-start",
              }}
            >
              <Box>
                <Flex
                  sx={{
                    alignItems: "center",
                    padding: "25px",
                    justifyContent: "center",
                  }}
                >
                  <StaticImage
                    src="../assets/img/omi.png"
                    sx={{
                      width: "120px",
                      height: "120px",
                      flexShrink: 0,
                      borderRadius: "50%",
                    }}
                    alt="Logo"
                    placeholder="blurred"
                  />
                  <Box ml="3">
                    <span sx={{ color: "#0082BE" }}>
                      <h3
                        sx={{
                          margin: "0",
                          fontSize: "18px",
                          fontWeight: "500",
                        }}
                      >
                        Omi Vaidya
                      </h3>
                    </span>
                    <span sx={{ color: "#4A4A49", fontSize: "16px" }}>
                      Comedian & Actor
                    </span>
                  </Box>
                </Flex>
              </Box>
              <Box>
                <Flex
                  sx={{
                    alignItems: "center",
                    padding: "25px",
                    justifyContent: "center",
                  }}
                >
                  <StaticImage
                    src="../assets/img/portfolio1.png"
                    sx={{
                      width: "120px",
                      height: "120px",
                      flexShrink: 0,
                      borderRadius: "50%",
                    }}
                    alt="Logo"
                    placeholder="blurred"
                  />
                  <Box ml="3">
                    <span sx={{ color: "#0082BE" }}>
                      <h3
                        sx={{
                          margin: "0",
                          fontSize: "18px",
                          fontWeight: "500",
                        }}
                      >
                        Shibani Kashyap
                      </h3>
                    </span>
                    <span sx={{ color: "#4A4A49", fontSize: "16px" }}>
                      Singer
                    </span>
                  </Box>
                </Flex>
              </Box>
            </Flex>
          )}
          <Flex
            sx={{
              alignItems: "baseline",
              position: "absolute",
              bottom: 0,
              left: "50%",
              width: "60px",
              margin: "0 0 0 -30px",
              marginBottom: "10px",
            }}
          >
            <button
              onClick={() => setToggle(true)}
              type="button"
              sx={{ border: "none", bg: "transparent", outline: "none" }}
            >
              <span
                sx={{
                  variant: "layout.dot",
                  width: `${"14px"}`,
                  height: `${"14px"}`,
                  cursor: "pointer",
                }}
              />
            </button>
            <button
              onClick={() => setToggle(false)}
              type="button"
              sx={{ border: "none", bg: "transparent", outline: "none" }}
            >
              <span
                sx={{
                  variant: "layout.dot",
                  width: `${"14px"}`,
                  height: `${"14px"}`,
                  cursor: "pointer",
                }}
              />
            </button>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(MOPSlider);
